<template>
 <div class="animated fadeIn">
    <div id="snackbar"></div>   
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Add Splash Screen </strong> <small>Form</small>
          </div>

            <b-form v-on:submit.prevent.self="submit">
                <b-row>                
                  <b-col sm="4">
                    <b-form-group>
                      <label for="splash_screen">Poster 1 (360 x 556)</label>
                      <b-form-file required v-on:change="uploadPoster($event.target, 1)" accept="image/jpeg, image/png, image/gif" ref="fileinput1" id="fileInput" :plain="true"></b-form-file>
                      <input type="hidden" v-model="splash_screen.Splash1"/>
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <b-form-group>
                      <label for="splash_screen">Poster 2 (360 x 556)</label>
                      <b-form-file required v-on:change="uploadPoster($event.target, 2)" accept="image/jpeg, image/png, image/gif" ref="fileinput2" id="fileInput" :plain="true"></b-form-file>
                      <input type="hidden" v-model="splash_screen.Splash2"/>
                    </b-form-group>
                  </b-col>            
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="4" id="preview1">                   
                    <img v-if="splash_screen.Splash1 != ''" :src="BaseURL+splash_screen.Splash1" class="img-fluid">
                  </b-col>

                  <b-col sm="4" id="preview2">
                    <img v-if="splash_screen.Splash2 != ''" :src="BaseURL+splash_screen.Splash2" class="img-fluid">
                  </b-col>
                </b-row>              
                
                <b-row>
                    <b-col sm="12">
                        <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                    </b-col>
                </b-row>
          </b-form>
        </b-card>


        <!-- List of Splash Screens -->
        <b-card>
          <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
            <div slot="Splash1" slot-scope="props">
              <img :src="props.row.Splash1" alt="Splash Image One" width="140" height="205"/>
            </div>

            <div slot="Splash2" slot-scope="props">
              <img :src="props.row.Splash2" alt="Splash Image One" width="120" height="199"/>
            </div>

            </v-client-table>
        </b-card>
        <!-- End List -->
      </b-col>
    </b-row>

    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import CinemaService from "@/services/CinemaService";
import UploadService from "@/services/UploadService";

import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import Vue from "vue";
export default {
  name: "SplashScreen",
  components: {
    cSwitch
  },
  data() {
    return {
      BaseURL: "",
      splash_screen: {
        SplashID: 1,
        Splash1: "",
        Splash2: ""
        // SplashIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      data: [],
      columns: ["Splash1", "Splash2"],

      options: {
        headings: {
          SplashScreen: "Splash",
          SplashIsActive: "Status"
          // SplashID: 'Edit',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getSplashScreen();
  },
  methods: {
    getSplashScreen: function() {
      this.data = [];
      CinemaService.getSplashScreen()
        .then(response => {
          const { data } = response;
          if (data.Status && data.SplashList.length > 0) {
            let obj = {};
            obj["Splash1"] =
              data.SplashList[0]["BaseURL"] + data.SplashList[0]["Splash1"];
            obj["Splash2"] =
              data.SplashList[0]["BaseURL"] + data.SplashList[0]["Splash2"];
            this.data.push(obj);
          } else {
            this.showMessageSnackbar(response.data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    async uploadPoster(image, posterno) {
      try {
        let self = this;
        var _URL = window.URL || window.webkitURL;
        var file, img;
        if ((file = image.files[0])) {
          img = new Image();
          img.src = _URL.createObjectURL(file);
          img.onload = async function() {
            if (img.height != 556 || img.width != 360) {
              if (posterno == 1) {
                self.$refs.fileinput1.reset();
              } else {
                self.$refs.fileinput2.reset();
              }
              self.showMessageSnackbar("Please upload a 360x556 Image");
            } else {
              var formdata = new FormData();
              formdata.append("assets/images/splash", image.files[0]);
              formdata.append("rename", "Splash1");
              // let res = await CinemaService.uploadFiles(formdata);
              const { data } = await UploadService.uploadFiles(formdata);
              data.Message ? self.showMessageSnackbar(data.Message) : "";
              if (data.Status) {
                if (posterno == 1) {
                  self.BaseURL = data.BaseURL;
                  self.splash_screen.Splash1 = data.Path;
                  // var previewDiv = document.getElementById("preview1");
                  // var previewImg = "";
                  // var previewImg = document.createElement("img");
                  // while (previewDiv.firstChild) {
                  //   previewDiv.removeChild(previewDiv.firstChild);
                  // }
                  // previewDiv.appendChild(previewImg);
                  // previewImg.src = window.URL.createObjectURL(image.files[0]);
                  // previewImg.classList.add("img-fluid");
                } else {
                  self.BaseURL = data.BaseURL;
                  self.splash_screen.Splash2 = data.Path;
                  // var previewDiv = document.getElementById("preview2");
                  // var previewImg = "";
                  // var previewImg = document.createElement("img");
                  // while (previewDiv.firstChild) {
                  //   previewDiv.removeChild(previewDiv.firstChild);
                  // }
                  // previewDiv.appendChild(previewImg);
                  // previewImg.src = window.URL.createObjectURL(image.files[0]);
                  // previewImg.classList.add("img-fluid");
                }
              }
            }
          };
        }
      } catch (error) {
        this.showMessageSnackbar(
          "Oops! Something went wrong. kindly contact your administrator"
        );
      }
    },
    submit: function() {
      let payload = {
        title: "Confirmation",
        message: "Please re-check the data before submit"
      };
      this.showSubmitConfirmation(payload);
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      CinemaService.addSplashScreen(this.splash_screen)
        .then(res => {
          if (res.data.Status) {
            this.showMessageSnackbar(res.data.Message);
            this.$refs.fileinput1.reset();
            this.$refs.fileinput2.reset();
            // while (document.getElementById("preview1").firstChild) {
            //   document
            //     .getElementById("preview1")
            //     .removeChild(document.getElementById("preview1").firstChild);
            // }
            // while (document.getElementById("preview2").firstChild) {
            //   document
            //     .getElementById("preview2")
            //     .removeChild(document.getElementById("preview2").firstChild);
            // }
            this.getSplashScreen();
          } else {
            this.showMessageSnackbar(res.data.Message);
          }
        })
        .catch(error => {
          // something went wrong kindly contact your administrator
          console.log("Catch on Splash Submit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End

    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }

    // async editSplash(SplashID) {
    //   console.log('SplashID :', SplashID);
    //   let res = await CinemaService.getSplashByID(SplashID)
    //   console.log('res :', res);
    //   this.splash_screen = res.data[0];

    //   var previewDiv = document.getElementById('preview1')
    //   var previewImg = ''
    //   var previewImg = document.createElement('img');
    //   while (previewDiv.firstChild) {
    //     previewDiv.removeChild(previewDiv.firstChild);
    //   }
    //   previewDiv.appendChild(previewImg);
    //   previewImg.src = 'http://ticketak.binarynumbers.io'+res.data[0].Splash1;
    //   previewImg.classList.add('img-fluid')

    //   var previewDiv = document.getElementById('preview2')
    //   var previewImg = ''
    //   var previewImg = document.createElement('img');
    //   while (previewDiv.firstChild) {
    //     previewDiv.removeChild(previewDiv.firstChild);
    //   }
    //   previewDiv.appendChild(previewImg);
    //   previewImg.src = 'http://ticketak.binarynumbers.io'+res.data[0].Splash2;
    //   previewImg.classList.add('img-fluid')
    // },
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>

